import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter);
export let routes = [
    {
        path: '/',
        name: 'index',
        title: '首页',
        component: () => import('./pages/index'),
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'home',
                component: () => import('./pages/pages/index'),
                meta: {
                    title: '科技金融'
                }
            }, {
                path: '/loan-amount-measurement',
                name: 'loan-amount-measurement',
                component: () => import('./pages/pages/loan-amount-measurement'),
                meta: {
                    title: '贷款测额'
                }
            },{
                path: '/apply-immediately',
                name: 'apply-immediately',
                component: () => import('./pages/pages/apply-immediately'),
                meta: {
                    title: '立即申请'
                }
            },{
                path: '/loan-info',
                name: 'loan-info',
                component: () => import('./pages/pages/loan-info'),
                meta: {
                    title: '贷款咨讯'
                }
            },{
                path: '/company-profile',
                name: 'company-profile',
                component: () => import('./pages/pages/company-profile'),
                meta: {
                    title: '公司简介'
                }
            },
        ],
    },

];


let router = new VueRouter({
        routes: routes,
        scrollBehavior: () => ({y: 0}),
    }
);
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    store.commit('changeMenu',false);
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
})

export default router;