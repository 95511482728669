import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
       showMenu:false,  //显示菜单
    },
    mutations: {
        changeMenu (state,b) { //改变菜单状态
            state.showMenu=b;
        }
    }
})
export  default store;