<template>
  <div id="app">
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  margin-top: 60px;
}
div{
  box-sizing: border-box;
}

.felx {
  display: flex;
}

.flex-a {
  align-items: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-space {
  align-items: center;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}
.felx-d{
  flex-direction: column;
}
.spage .footer{
  padding-bottom: 50px;
}
.l-input {
  width: 100%;
  height: 44px;
  background: #F8F8F8;
  border-radius: 22px;
  border: none;
  padding: 0 15px;
  font-size: 14px;
  margin-bottom: 10px;
}

.input-con {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  flex: 1;
}

.input-con::placeholder {
  color: #999999;
}
.line{
  width: 1px;
  height: 15px;
  background-color: #999999;
  margin:  0 15px;
}
.code-text{
  /*min-width: 45px;*/
  height: 100%;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  color: #FB560A;
}
.btn{
  height: 45px;
  border-radius: 25px;
  font-size: 18px;
  color: #FFFFFF;
  background-image: linear-gradient(#fa8048, #fb560a);
  /*background-color:#FB560A ;*/
  text-align: center;
  line-height: 45px;
  margin-top: 20px;
  display: block;
}
.active-code {
  color: #999999;
}

</style>
