import axios from "axios";
import baseUrl from "@/util/config";
import {Toast } from 'vant';

const instance = axios.create({
    baseURL: baseUrl.urlW,
    timeout: 1000,
    headers: {'X-Custom-Header': 'foobar'}
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
    });
    // 在发送请求之前做些什么
    if (config.changeUrl) {
        config.baseURL = baseUrl.urlX
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    Toast.clear();
    // 对响应数据做点什么
    if(response.data.code==200){
        return response;
    } else {
        Toast(response.data.message);
        return Promise.reject(response);
    }

}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance;