import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import request from "@/api/request";
Vue.prototype.$axios=request;
Vue.config.productionTip = false;
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
